<template>
  <vue-draggable-resizable
    :key="item.id"
    class="absolute flex shadow-md"
    :x="item.left"
    :y="item.top"
    :w="500"
    :h="200"
  >
    <v-card color="#ffffff" elevation="10">
      <v-card-text class="text-right">
        <v-btn icon>
          <v-icon> mdi-window-minimize </v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon> mdi-close-circle </v-icon>
        </v-btn>
      </v-card-text>
      <iframe
        :src="item.url"
        width="480"
        height="180"
        style="padding: 0 10px 5px 10px"
        frameborder="0"
      ></iframe>
    </v-card>
  </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";

export default {
  components: {
    VueDraggableResizable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
      required: true,
    },
  },
};
</script>