<template>
  <main>
    <h1>Zoom Meeting</h1>

    <!-- For Component View -->
    <div id="meetingSDKElement">
      <!-- Zoom Meeting SDK Component View Rendered Here -->
    </div>

    <div class="input-area">
      <v-text-field
        class="input-text-area"
        label="Display Name"
        v-model="userName"
      ></v-text-field>
      <v-text-field
        class="input-text-area"
        label="Meeting ID"
        v-model="meetingNumber"
      ></v-text-field>
      <v-text-field
        class="input-text-area"
        label="Password"
        type="password"
        v-model="passWord"
      ></v-text-field>
    </div>
    <button @click="getSignature">Join Meeting</button>
  </main>
</template>

<script>
import axios from "axios";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";

export default {
  name: "HelloWorldNew",
  created() {},
  data() {
    return {
      client: ZoomMtgEmbedded.createClient(),
      // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
      sdkKey: process.env.VUE_APP_SDK_KEY,
      meetingNumber: "",
      passWord: "",
      role: process.env.VUE_APP_ROLE,
      signatureEndpoint: process.env.VUE_APP_SIGNATURE_ENDPOINT,
      userEmail: process.env.VUE_APP_USER_EMAIL,
      userName: "",
      // pass in the registrant's token if your meeting or webinar requires registration. More info here:
      // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/component-view/meetings#join-registered
      // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/component-view/webinars#join-registered
      registrantToken: "",
    };
  },
  methods: {
    getSignature() {
      axios
        .post(this.signatureEndpoint, {
          meetingNumber: this.meetingNumber,
          role: this.role,
        })
        .then((res) => {
          console.log(res.data.signature);
          this.startMeeting(res.data.signature);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startMeeting(signature) {
      let meetingSDKElement = document.getElementById("meetingSDKElement");

      this.client.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: "en-US",
        customize: {
          meetingInfo: [
            "topic",
            "host",
            "mn",
            "pwd",
            "telPwd",
            "invite",
            "participant",
            "dc",
            "enctype",
          ],
          toolbar: {
            buttons: [
              {
                text: "Custom Button",
                className: "CustomButton",
                onClick: () => {
                  console.log("custom button");
                },
              },
            ],
          },
        },
      });

      this.client.join({
        sdkKey: this.sdkKey,
        signature: signature,
        meetingNumber: this.meetingNumber,
        password: this.passWord,
        userName: this.userName,
        userEmail: this.userEmail,
        tk: this.registrantToken,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  width: 100%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
}

.input-area {
  display: flex;
  margin: 10px;
}

.input-text-area {
  margin-right: 15px;
  margin-right: 15px;
}
</style>
