<template>
  <div id="app">
    <div class="zoom-display-area">
      <HelloWorldNew />
      <div class="graph-area">
        <WindowItem
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :order="item.order"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorldNew from "./components/HelloWorldNew.vue";
import WindowItem from "./components/WindowItem.vue";

export default {
  name: "App",
  components: {
    HelloWorldNew,
    WindowItem,
  },
  data() {
    return {
      items: [
        {
          id: "a",
          top: 30,
          left: 50,
          url: "https://" + process.env.VUE_APP_ICS_DOMAIN + "/gragh",
          order: 1,
        },
        {
          id: "b",
          top: 120,
          left: 50,
          url: "https://" + process.env.VUE_APP_ICS_DOMAIN + "/select",
          order: 2,
        },
      ],
    };
  },
};
</script>

<style>
#app {
  width: 70%;
  margin: auto;
  text-align: center;
}

#zmmtg-root {
  display: none;
}

.zoom-display-area {
  display: flex;
}

.graph-area {
  display: block;
}
</style>
